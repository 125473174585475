.licensing-submit-btn{
    background-color: #005980;
    color: #FFF;
    font-family: 'ClearSans-Bd', sans-serif;
}

.licensing-submit-btn:disabled{
    background-color: #747474;
}

.licensing-submit-btn__download-spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #747474;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 2s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.licensing-submit-btn--stacked{
    display: flex;
    flex-direction: column;
}

.licensing-submit-btn--stacked > .licensing-submit-btn{
    margin-bottom: 1rem;
    width: 6rem;
}